import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BatchForCreation, BatchRepositoryService, eBatchType, SelectOption } from 'reg-hub-common';

@Component({
  selector: 'app-new-batch',
  templateUrl: './new-batch.component.html',
  styleUrl: './new-batch.component.css'
})
export class NewBatchComponent implements OnInit {
  isSaving: boolean = false;
  collectionID!: string;
  newBatch: BatchForCreation = {
    batchTypeID: eBatchType.assetSearch,
    description: "",
    submissionRateInSeconds: 5
  }

  _batchTypes: SelectOption[] = [
    { label: "Asset Search", value: eBatchType.assetSearch },
    { label: "Debtor Search", value: eBatchType.debtorSearch },
    { label: "Business Search", value: eBatchType.businessSearch },
    // { label: "Business Report", value: eBatchType.businessReport },
    { label: "Renewal", value: eBatchType.renewal },
    { label: "Discharge", value: eBatchType.discharge },
  ]

  constructor(
    private batchesService: BatchRepositoryService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: ToastrService) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.collectionID = params.get('collectionID')!;
    })
  }

  createBatch() {
    this.isSaving = true;
    this.batchesService.createBatch(this.collectionID, this.newBatch).subscribe({
      next: (batch) => {
        this.router.navigate(['collections', this.collectionID,'draft-batch',batch.id]);
        this.isSaving = false;
      },
      error: err => {
        console.error(err);
        this.messageService.error("Failed to create batch");
        this.isSaving = false;
      },
    })
  }
}
