<div class="padding">
    <mat-card>
        <mat-card-content *ngIf="this.batch && this.collection">
            <div class="flex-container">
                <div class="flex-item">
                    <div class="flex-container">
                        <span class="flex-item">Collection:</span><a class="flex-item-2"
                            [routerLink]="['/collections', collection!.id]">{{ collection!.name }}</a>
                    </div>
                    <div class="flex-container">
                        <span class="flex-item">Description:</span><a class="flex-item-2">{{ collection!.description
                            }}</a>
                    </div>
                </div>
                <div class="flex-item">
                    <div class="flex-container">
                        <span class="flex-item">Batch Type:</span><a class="flex-item-2">{{ batch!.batchTypeID }}</a>
                    </div>
                    <div class="flex-container">
                        <span class="flex-item">Batch State:</span><a class="flex-item-2 bold">{{ batch!.batchStateID
                            }}</a>
                    </div>
                </div>
            </div>
            <div class="flex-container" *ngIf="!isNormalizing">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput name="description" [(ngModel)]="this.batch.description" placeholder="Description">
                </mat-form-field>
            </div>
            <div class="flex-container" *ngIf="!isNormalizing">
                <div class="flex-item">
                    <input #fileInput hidden="true" type="file" onclick="this.value = null"
                        (change)="onFileSelected($event)" accept=".pdf" />
                    <div class="flex-container">
                        <button mat-flat-button color="primary" class="flex-item" (click)="fileInput.click()">
                            {{ this._document ? this._document.file?.name ?? this._document.document?.fileName ??
                            'Batch File' : 'Upload' }}</button>
                        <mat-icon class="flex-item" (click)="onDeleteDocument()"
                            style="cursor: pointer;">delete</mat-icon>
                    </div>
                </div>
            </div>
            <div class="flex-container" *ngIf="!isNormalizing">
                <span class="spacer"></span>
                <button mat-raised-button (click)="saveBatch()" color="accent" class="apply-padding"
                    aria-label="Save Colleciton" [class.spinner]="this.isSaving"
                    [disabled]="this.isSaving">
                    Save
                </button>
            </div>
            <div class="flex-container-center" *ngIf="isNormalizing">
                <h2>Normalizing / Validating</h2>
            </div>
            <div class="flex-container-center" *ngIf="isNormalizing">
                <mat-spinner></mat-spinner>
            </div>
        </mat-card-content>
    </mat-card>
</div>