import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { UiCommonModule, RegHubDateAdapter, REG_HUB_DATE_FORMATS } from 'reg-hub-common';
import { environment } from '../../environments/environment';
import { SharedModule } from 'projects/reg-hub-admin/src/app/shared/shared.module';
import { CollectionsRoutingModule } from './collections-routing.module';
import { CollectionsFilterComponent } from './collections-filter/collections-filter.component';
import { CollectionsListComponent } from './collections-list/collections-list.component';
import { AddCollectionsComponent } from './add-collections/add-collections.component';
import { BatchesFilterComponent } from './batches-filter/batches-filter.component';
import { BatchesListComponent } from './batches-list/batches-list.component';
import { BatchComponent } from './batch/batch.component';
import { NewBatchComponent } from './new-batch/new-batch.component';
import { CollectionComponent } from './collection/collection.component';
import { DraftBatchComponent } from './draft-batch/draft-batch.component';

@NgModule({
    declarations: [
      CollectionsFilterComponent,
      CollectionsListComponent,
      AddCollectionsComponent,
      CollectionComponent,
      BatchesFilterComponent,
      BatchesListComponent,
      BatchComponent,
      NewBatchComponent,
      DraftBatchComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        UiCommonModule.forRoot({ rootUrl: environment.urlAddress, batchRootURL: environment.batchUrlAddress, consortiumRootURL: environment.consortiumUrlAddress }),
        CollectionsRoutingModule
    ],
    providers: [
      { provide: DateAdapter, useClass: RegHubDateAdapter},
      { provide: MAT_DATE_FORMATS, useValue: REG_HUB_DATE_FORMATS},
    ]
})
export class CollectionsModule { }