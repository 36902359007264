<div class="padding">
    <div class="flex-container">
        <h1 class="left-box">Add Batch</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="flex-container">
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-select [(ngModel)]="this.newBatch.batchTypeID" placeholder="Batch Type">
                        <mat-option *ngFor="let option of _batchTypes" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="form-field flex-item" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput name="description" [(ngModel)]="this.newBatch.description" placeholder="Description"></textarea>
                </mat-form-field>
            </div>
            <div class="flex-container">
                <span class="spacer"></span>
                <button mat-raised-button (click)="createBatch()" color="accent" class="apply-padding"
                    aria-label="Save Colleciton" [class.spinner]="this.isSaving"
                    [disabled]="this.isSaving">
                    Save
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>