import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';
import { ConfigurationState, Collection, CollectionsRepositoryService } from 'reg-hub-common';
import { FiltersComponent } from 'reg-hub-common';

@Component({
    selector: 'app-collections-filter',
    templateUrl: './collections-filter.component.html',
    styleUrls: ['./collections-filter.component.css'],
    providers: [DatePipe]
})
export class CollectionsFilterComponent extends FiltersComponent<Collection> implements OnInit {
    protected autoExpand: boolean = false;
    
    constructor(
        private route: ActivatedRoute,
        private configurationStateService: ConfigurationState,
        collectionsRepository: CollectionsRepositoryService,
        formBuilder: FormBuilder,
        datePipe: DatePipe,
    ) {
        super(
            collectionsRepository,
            formBuilder,
            datePipe);
    }

    ngOnInit(): void {
        this._filterForm = this.formBuilder.group({
            name: [null],
            isVisible: [true]
        });

        this.configurationStateService.getCorporationID().then(corporationID => {
                this.repo.populateBaseLink([corporationID]);
                this.getFirstPage();
        });
    }

    getFirstPage() {
        this._defaultParams = this.buildParamsFromForm(this._filterForm);

        this.route.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
            const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

            if (routeQueryStringParamKeys.length > 0) {
                let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

                routeQueryStringParamKeys.forEach(key => {
                    const control = this._filterForm.get(key);

                    if (control) {
                        control.setValue(routeQueryStringParams[key]);
                        this._filterForm.markAsDirty();
                        this.filtered = true;
                    }
                })

                this.repo.getFirstPage(preFilteredParams);
            } else {
                this.repo.getFirstPage(this._defaultParams);
            }
        });
    }
}