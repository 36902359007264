<div class="padding margin-top">
    <div class="flex-container">
        <h1 class="left-box">Collections</h1>
        <span class="spacer"></span>
        <button mat-fab matTooltip="Add Collection" [routerLink]="['add']"
            aria-label="Button that adds new collection." color="accent">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <app-collections-filter></app-collections-filter>
    <div class="table-container padding-top">
        <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows
            class="mat-elevation-z0" (matSortChange)="sort($event)"
            [class.table-loading]="this.repo.loading$ | async">

            <!-- Invoice ID -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let collection"> {{collection.name}} </td>
            </ng-container>

            <!-- Invoice ID -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let collection"> {{collection.description}} </td>
            </ng-container>

            <!-- Date Paid -->
            <ng-container matColumnDef="added">
                <th mat-header-cell *matHeaderCellDef> Added </th>
                <td mat-cell *matCellDef="let collection"> {{collection.added | localizedDate:'yyyy-MM-dd'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]">
            </tr>
        </table>
    </div>
    <mat-paginator *ngIf="(this.repo.resourceCount$ | async) ?? 0 > 0" [pageSize]="this.repo.pageSize$ | async"
        [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
        [pageSizeOptions]="[10, 25, 50]" (page)="page($event)"></mat-paginator>
</div>