import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionsListComponent } from './collections-list/collections-list.component';
import { CollectionComponent } from './collection/collection.component';
import { BatchComponent } from './batch/batch.component';
import { AddCollectionsComponent } from './add-collections/add-collections.component';
import { NewBatchComponent } from './new-batch/new-batch.component';
import { DraftBatchComponent } from './draft-batch/draft-batch.component';

const routes: Routes = [
    { 
        path: '',
        component: CollectionsListComponent
    },
    { path: 'add', component: AddCollectionsComponent, data: { breadcrumb: { label: 'Add', url: 'add' } } },
    { path: ':collectionID', component: CollectionComponent, data: { breadcrumb: { label: 'Collection', url: '00000000-0000-0000-0000-000000000000' } } },
    { path: ':collectionID/new-batch', component: NewBatchComponent, data: { breadcrumb: { label: 'New Batch', url: '00000000-0000-0000-0000-000000000000/new-batch' } } },
    { path: ':collectionID/draft-batch/:batchID', component: DraftBatchComponent, data: { breadcrumb: { label: 'Batch', url: '00000000-0000-0000-0000-000000000000/draft-batch/00000000-0000-0000-0000-000000000000' } } },
    { path: ':collectionID/batches/:batchID', component: BatchComponent, data: { breadcrumb: { label: 'Batch', url: '00000000-0000-0000-0000-000000000000/batches/00000000-0000-0000-0000-000000000000' } } }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class CollectionsRoutingModule { }