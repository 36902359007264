import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Batch, BatchRepositoryService, BatchSummary, BatchSummaryRepositoryService, Collection, CollectionsRepositoryService, ConfigurationState } from 'reg-hub-common';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrl: './batch.component.css'
})
export class BatchComponent implements OnInit {
    collectionID: string = '';
    collection: Collection | null = null;
    batchID: string = '';
    batch: Batch | null = null;
    batchSummary: BatchSummary | null = null;
    stagedProgress: number = 0;
    submittedProgress: number = 0;
    completedProgress: number = 0;
    totalProgress: number = 0;
    showNormalizedDocument: boolean = false;

    constructor(private activatedRoute: ActivatedRoute,
      private collectionRepositoryService: CollectionsRepositoryService,
      private batchRepositoryService: BatchRepositoryService,
      private batchSummaryRepositoryService: BatchSummaryRepositoryService,
      private configurationState: ConfigurationState,
      private router: Router
    ) { }

    ngOnInit(): void {
      this.activatedRoute.paramMap.subscribe(params => {
        this.collectionID = params.get('collectionID')!;
        this.batchID = params.get('batchID')!;
        this.getCollection();
        this.getBatch();
        this.getBatchSummary();
      });
    }

    getCollection() {
      this.configurationState.getCorporationID().then(corporationID => {
        this.collectionRepositoryService.populateBaseLink([corporationID]);
        this.collectionRepositoryService.getCollection(this.collectionID).subscribe(collection => {
          this.collection = collection;
        })
      })
    }

    getBatch() {
      this.batchRepositoryService.getBatch(this.collectionID, this.batchID).subscribe(batch => {
        this.batch = batch;
        this.showNormalizedDocument = this.batch.normalizedDocument?.id != this.batch.rawDocument?.id;
      })
    }

    getBatchSummary() {
      this.batchSummaryRepositoryService.getBatchSummary(this.collectionID, this.batchID).subscribe(summary => {
        this.batchSummary = summary;
        if (summary.totalOrders > 0) 
        {
          this.stagedProgress = (summary.stagedOrders/summary.totalOrders)*100;
          this.submittedProgress = (summary.submittedOrders/summary.totalOrders)*100;
          this.completedProgress = (summary.completedOrders/summary.totalOrders)*100;
          this.totalProgress = ((summary.stagedOrders + summary.submittedOrders + summary.completedOrders)/(summary.totalOrders*3));
        }
      })
    }
}
