import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Batch, BatchRepositoryService, ConfigurationState, FiltersComponent } from 'reg-hub-common';
import { take } from 'rxjs';

@Component({
  selector: 'app-batches-filter',
  templateUrl: './batches-filter.component.html',
  styleUrl: './batches-filter.component.css'
})
export class BatchesFilterComponent extends FiltersComponent<Batch> implements OnInit {
  protected autoExpand: boolean = false;
  protected collectionID: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    batchesRepository: BatchRepositoryService,
    formBuilder: FormBuilder,
    datePipe: DatePipe
  ) {
    super(
      batchesRepository,
      formBuilder,
      datePipe);
  }

  ngOnInit(): void {
    this._filterForm = this.formBuilder.group({
      isVisible: [true]
    });

    this.activatedRoute.paramMap.subscribe(params => {
      this.collectionID = params.get('collectionID')!;
        this.repo.populateBaseLink([this.collectionID]);
        this.getFirstPage();
    });
  }

  getFirstPage() {
    this._defaultParams = this.buildParamsFromForm(this._filterForm);

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(routeQueryStringParams => {
      const routeQueryStringParamKeys = Object.keys(routeQueryStringParams);

      if (routeQueryStringParamKeys.length > 0) {
        let preFilteredParams = this.buildParamsFromObject(routeQueryStringParams);

        routeQueryStringParamKeys.forEach(key => {
          const control = this._filterForm.get(key);

          if (control) {
            control.setValue(routeQueryStringParams[key]);
            this._filterForm.markAsDirty();
            this.filtered = true;
          }
        })

        this.repo.getFirstPage(preFilteredParams);
      } else {
        this.repo.getFirstPage(this._defaultParams);
      }
    });
  }
}
