import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Collection, CollectionsRepositoryService, ConfigurationState } from 'reg-hub-common';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrl: './collection.component.css'
})
export class CollectionComponent implements OnInit {
  collectionID: string = '';
  collection!: Collection;
  selectedIndex: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
    private collectionsRepo: CollectionsRepositoryService,
    private configurationStateService: ConfigurationState,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.collectionID = params.get('collectionID')!;
      this.getCollection();
    });
  }

  getCollection() {
    this.configurationStateService.getCorporationID().then(corporationID => {
      this.collectionsRepo.populateBaseLink([corporationID]);
      this.collectionsRepo.getCollection(this.collectionID).subscribe(collection => {
        this.collection = collection;
      })
    })
  }
}
