import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Base64Service, Batch, BatchRepositoryService, BatchStateRepositoryService, Collection, CollectionsRepositoryService, ConfigurationState, UploadedDocument, BatchStateForUpdate, eBatchState, BatchDocumentsRepositoryService, DocumentBinary, BatchForUpdate } from 'reg-hub-common';
import { interval, Subscription, switchMap, takeWhile } from 'rxjs';

@Component({
  selector: 'app-draft-batch',
  templateUrl: './draft-batch.component.html',
  styleUrl: './draft-batch.component.css'
})
export class DraftBatchComponent implements OnInit {
  isSaving: boolean = false;
  isNormalizing: boolean = false;
  collectionID!: string;
  collection!: Collection;
  batchID!: string;
  batch!: Batch;
  protected _document: UploadedDocument | null = null;
  private pollingSubscription!: Subscription;

  constructor(
    private batchesService: BatchRepositoryService,
    private batchDocumentsService: BatchDocumentsRepositoryService,
    private batchStatesService: BatchStateRepositoryService,
    private collectionsService: CollectionsRepositoryService,
    private configurationState: ConfigurationState,
    private messageService: ToastrService,
    private base64Service: Base64Service,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.collectionID = params.get('collectionID')!;
      this.batchID = params.get('batchID')!;
      this.getBatch();
      this.getCollection();
    });
  }

  ngOnDestroy() {
    this.stopPolling();
  }

  getCollection() {
    this.configurationState.getCorporationID().then(corporationID => {
      this.collectionsService.populateBaseLink([corporationID]);
      this.collectionsService.getCollection(this.collectionID).subscribe(collection => {
        this.collection = collection;
      })
    })
  }

  getBatch() {
    this.configurationState.getCorporationID().then(corporationID => {
      this.batchesService.populateBaseLink([corporationID]);
      this.batchesService.getBatch(this.collectionID, this.batchID).subscribe(batch => {
        this.batch = batch;
      })
    });
  }

  protected onFileSelected(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let file = element.files ? element.files[0] : null;

    if (file !== null) {
      this.base64Service.convertToBase64(file)
        .subscribe(base64 => {
          this._document = {
            binary: base64,
            file: file
          };
        });
    }
  }

  protected onDeleteDocument() {
    this._document = null;
  }

  saveBatch() {
    this.isSaving = true;
    //Upload Document
    if (this._document?.file) {
      let document: DocumentBinary = {
        fileName: this._document?.file!.name!,
        documentTypeID: this.getDocumentType(this._document!.file!),
        base64: this._document!.binary,
      }
      this.batchDocumentsService.createDocumentBinary(document).subscribe(document => {
        this.updateBatch(document.id);
      })
    }
    else {
      this.updateBatch(this._document!.document!.id);
    }
  }

  updateBatch(documentID: string) {
    let batchForUpdate = this.batch as BatchForUpdate;
    batchForUpdate.rawDocumentID = documentID;
    this.batchesService.updateBatch(this.collectionID, this.batchID, batchForUpdate).subscribe({
      next: () => {
        this.submitBatch();
      },
      error: err => {
        console.error(err);
        this.messageService.error("Failed to save batch");
      }
    })
  }

  getDocumentType(file: File): string {
    switch (file.type) {
      case 'application/pdf':
        return 'PDF';
      case 'text/plain':
        return 'Text';
      case 'application/xml':
      case 'text/xml':
        return 'XML';
      case 'text/html':
        return 'HTML';
      case 'application/json':
        return 'JSON';
      case 'text/csv':
      case 'application/vnd.ms-excel': // Some browsers use this for CSV
        return 'CSV';
      default:
        return 'PDF';
    }
  }

  submitBatch() {
    //Change Batch State to Normlization
    let newState: BatchStateForUpdate = {
      newState: eBatchState.Normalization
    };
    this.batchStatesService.updateBatchState(this.collectionID, this.batchID, newState).subscribe({
      next: () => {
        this.isSaving = false;
        this.isNormalizing = true;
        this.pollBatch();
      },
      error: err => {
        console.error(err);
        this.messageService.error("Failed to save batch");
      }
    })
  }

  pollBatch() {
    this.pollingSubscription = interval(1000)
    .pipe(switchMap(() => this.batchesService.getBatch(this.collectionID, this.batchID)),
    takeWhile(batch => batch.batchStateID == eBatchState.Normalization, true))
    .subscribe(batch => {
      this.batch = batch;
      if (this.batch.batchStateID == eBatchState.draft) {
        this.isNormalizing = false;
      }
      else if (this.batch.batchStateID != eBatchState.Normalization) {
        this.router.navigate(['collections', this.collectionID, 'batches', this.batchID]);
        this.stopPolling();
      }
    });
  }

  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }
}
