import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Batch, BatchRepositoryService, eBatchState, ListComponent } from 'reg-hub-common';

@Component({
  selector: 'app-batches-list',
  templateUrl: './batches-list.component.html',
  styleUrl: './batches-list.component.css'
})
export class BatchesListComponent extends ListComponent<Batch> {
displayedColumns: string[] = [
    'description',
    'type',
    'state',
    'added'
  ];

  constructor(
      repo: BatchRepositoryService,
      private router: Router) {
      super(repo);
    }
  
    ngOnInit(): void {
  
    }

    navigateToBatch(batch: Batch) {
      if (batch.batchState.id == eBatchState.draft || 
        batch.batchState.id == eBatchState.Normalization) {
          this.router.navigate(['collections', batch.collectionID, 'draft-batch', batch.id]);
      }
      else {
        this.router.navigate(['collections', batch.collectionID, 'batches', batch.id]);
      }
    }
}
