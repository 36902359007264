<div class="padding" *ngIf="this.collection">
    <div class="flex-container">
        <h1 class="left-box">{{this.collection.name}}</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="flex-container">
                {{this.collection.description}}
            </div>
            <div class="table-container">
                <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex">
                    <mat-tab label="Batches">
                        <app-batches-list></app-batches-list>
                    </mat-tab>
                    <mat-tab label="Order Groups">
                        <p>Order Groups will go here!</p>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
    </mat-card>
</div>