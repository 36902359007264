<div class="padding">
    <div class="flex-container">
        <h1 class="left-box">Add Collection</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <div class="flex-container">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput name="name" [(ngModel)]="this.newCollection.name" placeholder="Name">
                </mat-form-field>
            </div>
            <div class="flex-container">
                <mat-form-field class="form-field" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput name="description" [(ngModel)]="this.newCollection.description" placeholder="Description"></textarea>
                </mat-form-field>
            </div>
            <div class="flex-container">
                <span class="spacer"></span>
                <button mat-raised-button (click)="saveCollection()" color="accent" class="apply-padding"
                    aria-label="Save Colleciton" [class.spinner]="this.isSaving"
                    [disabled]="this.isSaving">
                    Save
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
