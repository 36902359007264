import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CollectionForCreation, CollectionsRepositoryService, ConfigurationState } from 'reg-hub-common';

@Component({
  selector: 'app-add-collections',
  templateUrl: './add-collections.component.html',
  styleUrl: './add-collections.component.css'
})
export class AddCollectionsComponent implements OnInit {
  isSaving: boolean = false;
  newCollection: CollectionForCreation = {
    name: "",
    description: ""
  }

  constructor(
    private collectionsService: CollectionsRepositoryService,
    private configurationState: ConfigurationState,
    private messageService: ToastrService,
    private router: Router) {

  }
  
  ngOnInit(): void {
    this.configurationState.getCorporationID().then(corporationID => {
      this.collectionsService.populateBaseLink([corporationID]);
    })
  }

  saveCollection() {
    this.isSaving = true;
    this.collectionsService.createCollection(this.newCollection).subscribe({
      next: () => {
        this.router.navigate(['collections']);
        this.isSaving = false;
      },
      error: err => {
        console.error(err);
        this.messageService.error("Failed to save collection");
        this.isSaving = false;
      },
    })
  }
}
