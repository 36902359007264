<div class="padding margin-top">
    <div class="flex-container">
        <span class="spacer"></span>
        <button mat-fab matTooltip="Add Batch" [routerLink]="['new-batch']"
            aria-label="Button that adds new batch." color="accent">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <app-batches-filter></app-batches-filter>
    <div class="table-container padding-top" *ngIf="this.repo.resource$ | async as data">
        <div class="table-container" *ngIf="data">
            <table mat-table matSort [dataSource]="this.repo.resource$" multiTemplateDataRows class="mat-elevation-z0"
                (matSortChange)="sort($event)" [class.table-loading]="this.repo.loading$ | async"
                *ngIf="data.length > 0">

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description </th>
                    <td mat-cell *matCellDef="let batch"> {{batch.description}} </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let batch"> {{batch.batchType.name}} </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef> State </th>
                    <td mat-cell *matCellDef="let batch"> {{batch.batchState.name}} </td>
                </ng-container>

                <ng-container matColumnDef="added">
                    <th mat-header-cell *matHeaderCellDef> Added </th>
                    <td mat-cell *matCellDef="let batch"> {{batch.added | localizedDate:'yyyy-MM-dd'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToBatch(row)">
                </tr>
            </table>
        </div>
        <mat-paginator *ngIf="data && (this.repo.resourceCount$ | async) ?? 0 > 0" [pageSize]="this.repo.pageSize$ | async"
            [length]="this.repo.resourceCount$ | async" [pageIndex]="this.repo.pageNum$ | async"
            [pageSizeOptions]="[10, 25, 50]" (page)="page($event)"></mat-paginator>
        <lib-list-issue [repo]="this.repo" [data]="data"></lib-list-issue>
    </div>
</div>