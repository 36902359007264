import { Component } from '@angular/core';
import { Collection, CollectionsRepositoryService, ListComponent } from 'reg-hub-common';

@Component({
  selector: 'app-collections-list',
  templateUrl: './collections-list.component.html',
  styleUrl: './collections-list.component.css'
})
export class CollectionsListComponent extends ListComponent<Collection> {

  displayedColumns: string[] = [
    'name',
    'description',
    'added'
  ];

  constructor(
      repo: CollectionsRepositoryService) {
      super(repo);
    }
  
    ngOnInit(): void {
  
    }
}
