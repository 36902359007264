<div class="padding">
    <div class="flex-container">
        <h1 class="left-box">{{this.batch?.description}}</h1>
        <span class="spacer"></span>
        <lib-batch-document-download *ngIf="showNormalizedDocument" 
            [documentID]="batch!.normalizedDocument!.id"
            [documentName]="'Normalized Batch'">
        </lib-batch-document-download>
        &nbsp;
        <lib-batch-document-download *ngIf="batch?.rawDocument" 
            [documentID]="batch!.rawDocument!.id"
            [documentName]="'Batch'">
        </lib-batch-document-download>
    </div>
    <mat-card>
        <mat-card-content *ngIf="this.batch && this.collection">
            <div class="flex-container">
                <div class="flex-item">
                    <div class="flex-container">
                        <span class="flex-item">Collection:</span><a class="flex-item-2" [routerLink]="['/collections', collection!.id]" >{{ collection!.name }}</a>
                    </div>
                    <div class="flex-container">
                        <span class="flex-item">Description:</span><a class="flex-item-2">{{ collection!.description }}</a>
                    </div>
                    <div class="flex-container">
                        <span class="flex-item">Batch:</span><a class="flex-item-2">{{ batch!.description }}</a>
                    </div>
                    <div class="flex-container">
                        <span class="flex-item">Batch Type:</span><a class="flex-item-2">{{ batch!.batchTypeID }}</a>
                    </div>
                    <div class="flex-container">
                        <span class="flex-item">Batch State:</span><a class="flex-item-2 bold">{{ batch!.batchStateID }}</a>
                    </div>
                </div>
                <div class="flex-item">
                    <div class="custom-progress-circle">
                        <svg viewBox="0 0 36 36">
                            <path
                                class="background-circle"
                                d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831">
                            </path>
                            <path
                                class="progress-circle"
                                [attr.stroke-dasharray]="100"
                                [attr.stroke-dashoffset]="100-(totalProgress*100)"
                                d="M18 2.0845
                                    a 15.9155 15.9155 0 0 1 0 31.831
                                    a 15.9155 15.9155 0 0 1 0 -31.831">
                            </path>
                        </svg>
                        <div class="progress-text">
                            <p data-testid="lein-lifetime-remaining-text">Total Progress {{totalProgress | percent}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-container">
                <div class="custom-progress-circle">
                    <svg viewBox="0 0 36 36">
                        <path
                            class="background-circle"
                            d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831">
                        </path>
                        <path
                            class="progress-circle"
                            [attr.stroke-dasharray]="100"
                            [attr.stroke-dashoffset]="100-stagedProgress"
                            d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831">
                        </path>
                    </svg>
                    <div class="progress-text">
                        <p data-testid="lein-lifetime-remaining-text">Staged {{batchSummary?.stagedOrders}}/{{batchSummary?.totalOrders}}</p>
                    </div>
                </div>
                <div class="custom-progress-circle">
                    <svg viewBox="0 0 36 36">
                        <path
                            class="background-circle"
                            d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831">
                        </path>
                        <path
                            class="progress-circle"
                            [attr.stroke-dasharray]="100"
                            [attr.stroke-dashoffset]="100-submittedProgress"
                            d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831">
                        </path>
                    </svg>
                    <div class="progress-text">
                        <p data-testid="lein-lifetime-remaining-text">Submitted {{batchSummary?.submittedOrders}}/{{batchSummary?.totalOrders}}</p>
                    </div>
                </div>
                <div class="custom-progress-circle">
                    <svg viewBox="0 0 36 36">
                        <path
                            class="background-circle"
                            d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831">
                        </path>
                        <path
                            class="progress-circle"
                            [attr.stroke-dasharray]="100"
                            [attr.stroke-dashoffset]="100-completedProgress"
                            d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831">
                        </path>
                    </svg>
                    <div class="progress-text">
                        <p data-testid="lein-lifetime-remaining-text">Complete {{batchSummary?.completedOrders}}/{{batchSummary?.totalOrders}}</p>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
        </mat-card-content>
    </mat-card>
</div>