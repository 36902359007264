<mat-expansion-panel [expanded]="autoExpand">
    <mat-expansion-panel-header>
        <mat-panel-title class="bold">
            Filters
        </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="_filterForm" class="padding">
        <div class="flex-container">
            <mat-form-field class="form-field flex-item">
                <mat-label>Name</mat-label>
                <input matInput [formControlName]="'name'" placeholder="Name" />
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Clear filters button"
                (click)="onClear()" [disabled]="!_filterForm.dirty">
                Clear Filters
            </button>
            <button mat-raised-button color="accent" class="margin-sides-5" aria-label="Filter button" type="submit"
                (click)="onFilter()">
                Filter
            </button>
        </div>
    </form>
</mat-expansion-panel>